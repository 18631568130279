import React, { useState, useRef } from "react";
import "./SuggestNewDish.css";

function FeatureSuggestion({ NewDishScreen }) {
  const initialState = {
    featureName: "",
    featureDescription: "",
  };
  const fileInputRef = useRef();
  const [formData, setFormData] = useState(initialState);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const url = "https://hungrybudget.com/endpoints/featureRequest.php";
    const data = new FormData();

    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        setFormData({ ...initialState, dishPicture: null });
        fileInputRef.current.value = "";
        setIsFormSubmitted(true);
        setTimeout(() => {
          setIsFormSubmitted(false);
        }, 1000);
        alert("Thank you for your suggestion! We will review it shortly 😊");
      } else {
        alert(
          "Something went wrong 😞. Please refresh the page and try again."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='new-dish-wrapper'>
      <div className='back' onClick={NewDishScreen}>
        Back
      </div>
      <h2 style={{ textAlign: "center" }}>New Dish Suggestion</h2>
      <form
        className='suggestionForm'
        encType='multipart/form-data'
        onSubmit={handleSubmit}>
        <div>
          <label htmlFor='featureName'>Feature Name*</label>
          <input
            className='suggestionInput'
            type='text'
            id='featureName'
            name='featureName'
            placeholder='Ex. Filter by Protein'
            value={formData.featureName}
            onChange={handleInputChange}
            maxLength={25}
            required
          />
        </div>

        <div className='descriptionForm'>
          <label htmlFor='featureDescription'>Feature Description*</label>
          <textarea
            className='descriptionInputSuggestion'
            id='featureDescription'
            name='featureDescription'
            placeholder='Description (Please elaborate on the feature)'
            value={formData.featureDescription}
            onChange={handleInputChange}
            maxLength={1000}
            required
          />
        </div>

        <button type='submit'>Submit</button>
      </form>
      <a
        className='privacy-policy-dish-suggestion'
        href='http://hungrybudget.com/privacyPolicy.html'>
        Privacy Policy
      </a>
    </div>
  );
}

export default FeatureSuggestion;

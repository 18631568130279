import React, { useEffect, useState, useContext } from "react";
import AppContext from "../context/AppContext";
import axios from "axios";
import "./FunBudget.css";

function FunBudget({ backToFormScreen }) {
  const appContext = useContext(AppContext);
  const [carouselImages, setCarouselImages] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [imageInterval, setImageInterval] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showAllDescription, setShowAllDescription] = useState(false);

  const fetchImages = () => {
    axios
      .post("https://hungrybudget.com/endpoints/FunBudget.php", {
        ...appContext.formData,
        funBudget: true,
      })
      .then((response) => {
        setCarouselImages(response.data.slice(0, 20));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const updateCurrentImage = () => {
    setCurrentImage(
      (prevCurrentImage) => (prevCurrentImage + 1) % carouselImages.length
    );
  };

  useEffect(() => {
    if (carouselImages.length > 0 && !imageInterval) {
      setImageInterval(setInterval(updateCurrentImage, 300));
    }
    return () => {
      clearInterval(imageInterval);
    };
  }, [carouselImages]);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
    clearInterval(imageInterval);
    setImageInterval(null);
  };

  const restartCarousel = () => {
    setCurrentImage(0);
    setShowDetails(false);
    fetchImages();
  };

  const toggleShowAllDescription = () => {
    setShowAllDescription(!showAllDescription);
  };

  const renderDescription = () => {
    const { dishDescription } = carouselImages[currentImage];
    if (dishDescription.length <= 150 || showAllDescription) {
      return (
        <>
          <p>{dishDescription}</p>
          {dishDescription.length > 150 && (
            <div
              className='show-description'
              onClick={toggleShowAllDescription}>
              Show less
            </div>
          )}
        </>
      );
    } else {
      const truncatedDescription = dishDescription.substr(0, 150);
      return (
        <>
          <p>
            {truncatedDescription}
            <span
              className='show-description'
              onClick={toggleShowAllDescription}>
              ...Read more
            </span>
          </p>
        </>
      );
    }
  };

  const handleAddressClick = () => {
    const address = carouselImages[currentImage].restaurantAddress;
    if (/(android)/i.test(navigator.userAgent)) {
      window.location.href = `geo:0,0?q=${address}`;
    } else {
      window.open(`http://maps.apple.com/?q=${address}`, "_blank");
    }
  };

  return (
    <div
      className={`wrapper-fun-budget ${
        !showDetails ? "row-direction" : "wrapper-fun-budget"
      }`}>
      {carouselImages.length > 0 && (
        <>
          <img
            className='carousel-image'
            src={`https://www.hungrybudget.com/images/${carouselImages[currentImage].dishPicture}`}
            alt={carouselImages[currentImage].dishName}
          />

          {!showDetails && (
            <div className='fun-stop-button' onClick={toggleDetails}>
              Stop!
            </div>
          )}
          {showDetails && (
            <div className='dish-details'>
              <p className='fun-dish-title'>
                {carouselImages[currentImage].dishName}
              </p>

              <p className='content fun-budget-price'>
                ${carouselImages[currentImage].dishPrice}
              </p>
              <p className='fun-description'>{renderDescription()}</p>
              <div className='restaurant-info-fun-section'>
                <button
                  className='fun-take-me-there'
                  onClick={handleAddressClick}>
                  Take me there!
                </button>
                <div className='fun-restaurnat-name'>
                  <p className='section-title'>Restaurant</p>
                  <p>{carouselImages[currentImage].restaurantName}</p>
                </div>
              </div>
              <button onClick={restartCarousel}>Restart Carousel</button>
              <button
                className='fun-go-to-form-screen-button'
                onClick={backToFormScreen}>
                Go To Form Screen
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FunBudget;

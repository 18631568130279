import React, { useContext, useState } from "react";
import AppContext from "../context/AppContext";
import axios from "axios";
import "./ResultScreen.css";

function ResultScreen() {
  const appContext = useContext(AppContext);
  const [showAllDescription, setShowAllDescription] = useState(false);

  const newSuggestion = () => {
    setShowAllDescription(false);
    axios
      .post(
        "https://hungrybudget.com/endpoints/dishes.php",
        appContext.formData
      )
      .then((response) => {
        appContext.setDishData(response.data[0]);
        axios
          .post("https://hungrybudget.com/endpoints/incrementFoodCount.php", {
            foodId: response.data[0].id,
          })
          .then(() => {
            //nothing is needed here
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeFilters = () => {
    appContext.setFormData({});
    appContext.setDishData(null);
    appContext.setFormError("");
  };

  const handleAddressClick = () => {
    const address = appContext.dishData.restaurantAddress;
    if (/(android)/i.test(navigator.userAgent)) {
      window.location.href = `geo:0,0?q=${address}`;
    } else {
      window.open(`http://maps.apple.com/?q=${address}`, "_blank");
    }

    axios
      .post("https://hungrybudget.com/endpoints/addressLinkClick.php", {
        foodId: appContext.dishData.id,
      })
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleShowAllDescription = () => {
    setShowAllDescription(!showAllDescription);
  };

  const renderDescription = () => {
    const { dishDescription } = appContext.dishData;
    if (dishDescription.length <= 150 || showAllDescription) {
      return (
        <>
          <p className='content description'>{dishDescription}</p>
          {dishDescription.length > 150 && (
            <div
              style={{
                color: "#07a",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              className='show-less'
              onClick={toggleShowAllDescription}>
              Show less
            </div>
          )}
        </>
      );
    } else {
      const truncatedDescription = dishDescription.substr(0, 150);
      return (
        <>
          <p className='content description'>
            {truncatedDescription}
            <span className='read-more' onClick={toggleShowAllDescription}>
              ...Read more
            </span>
          </p>
        </>
      );
    }
  };

  return (
    <div>
      {appContext.dishData ? (
        <div className='wrapper'>
          <div className='image-side'>
            <img
              className='image'
              src={
                "https://www.hungrybudget.com/images/" +
                appContext.dishData.dishPicture
              }
              alt={appContext.dishData.dishName}
            />
          </div>
          <div className='info-buttons'>
            <div className='info-side'>
              <div className='dish-title'>
                <div>{appContext.dishData.dishName}</div>
              </div>
              <p className='content price-display'>
                ${appContext.dishData.dishPrice}
              </p>
              <p className='section-title'>Description</p>
              {renderDescription()}
              <div className='take-me-there'>
                <div
                  className='take-me-there-button'
                  onClick={handleAddressClick}>
                  Take me there!
                </div>
                <div className='restaurnat-name'>
                  <p className='section-title'>Restaurant</p>
                  <p className='content'>
                    {appContext.dishData.restaurantName}
                  </p>
                </div>
              </div>
            </div>
            <div className='bottom-buttons'>
              <button
                className='button-bottom next-meal-button'
                onClick={newSuggestion}>
                Next Meal
              </button>
              <button
                className='button-bottom change-filters-button'
                onClick={changeFilters}>
                Change filters
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
export default ResultScreen;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./GeneralInfo.css";

function GeneralInfo() {
  const [oremData, setOremData] = useState([]);
  const [provoData, setProvoData] = useState([]);
  const [lowestPrice, setLowestPrice] = useState([]);
  const [highestPrice, setHighestPrice] = useState([]);
  const [mostCommonPrice, setMostCommonPrice] = useState([]);
  useEffect(() => {
    axios
      .get(
        "https://hungrybudget.com/endpoints/general-info/orem-dishes-count.php"
      )
      .then((response) => {
        setOremData(response.data);
      });

    axios
      .get(
        "https://hungrybudget.com/endpoints/general-info/provo-dishes-count.php"
      )
      .then((response) => {
        setProvoData(response.data);
      });

    axios
      .get(
        "https://hungrybudget.com/endpoints/general-info/most-common-price.php"
      )
      .then((response) => {
        setMostCommonPrice(response.data);
      });

    axios
      .get("https://hungrybudget.com/endpoints/general-info/lowest-price.php")
      .then((response) => {
        setLowestPrice(response.data);
      });

    axios
      .get("https://hungrybudget.com/endpoints/general-info/highest-price.php")
      .then((response) => {
        setHighestPrice(response.data);
      });
  });

  return (
    <div className="general-info">
      <p className="dish-count">Orem Dish Count: {oremData}</p>
      <p className="dish-count">Provo Dish Count: {provoData}</p>
      <p className="dish-count">Lowest Price: ${lowestPrice}</p>
      <p className="dish-count">Highest Price: ${highestPrice}</p>
      <p className="dish-count">Most Common Price: ${mostCommonPrice}</p>
    </div>
  );
}

export default GeneralInfo;

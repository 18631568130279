import React, { useState } from "react";
import AppContext from "../context/AppContext";
import axios from "axios";

function AppProvider(props) {
  const [formError, setFormError] = useState(null);
  const { disclaimer, setDisclaimer, dishData, setDishData } = props;

  const [formData, setFormData] = useState({
    minBudget: "",
    maxBudget: "",
    course: "",
    city: "",
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    axios
      .post("https://hungrybudget.com/endpoints/dishes.php", formData)
      .then((response) => {
        setDishData(response.data[0]);

        axios
          .post("https://hungrybudget.com/endpoints/incrementFoodCount.php", {
            foodId: response.data[0].id,
          })
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setFormError(
          "Oopsie! Can't find a dish with this specifications, please change your inputs and try again"
        );
      });
  };

  const value = {
    disclaimer,
    setDisclaimer,
    formData,
    setFormData,
    dishData,
    setDishData,
    handleFormSubmit,
    formError,
    setFormError,
  };

  return (
    <AppContext.Provider value={value}>{props.children}</AppContext.Provider>
  );
}

export default AppProvider;

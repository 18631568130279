import React, { useContext } from "react";
import AppContext from "../context/AppContext";
import "./DisclaimerScreen.css";
import Logo from "./HungryBudgetLogo.png";

function DisclaimerScreen() {
  const appContext = useContext(AppContext);

  const handleClick = () => {
    appContext.setDisclaimer((prevDisclaimer) => !prevDisclaimer);
  };

  return (
    <div className='disclaimer-wrapper'>
      {appContext.disclaimer && (
        <div className='disclaimer'>
          <div className='logo-wrapper'>
            <img className='logo' src={Logo} alt='Hungry Budget Logo' />
          </div>
          <p className='disclaimer-text'>
            The menu items that will be mentioned are subject to change by the
            restaurants and we cannot guarantee the accuracy of the prices
            mentioned at the time of your visit to the restaurant.
          </p>
          <p className='disclaimer-second-text'>
            Please note that all prices displayed in the app do not include
            taxes and are exclusive of any applicable taxes.
          </p>
          <a
            className='privacy-policy-disclaimer'
            href='http://hungrybudget.com/privacyPolicy.html'>
            Privacy Policy
          </a>
          <button className='disclaimer-button' onClick={handleClick}>
            Agree
          </button>
        </div>
      )}
    </div>
  );
}

export default DisclaimerScreen;

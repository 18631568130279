import React, { useState, useEffect } from "react";
import "./ToggleButton.css";

function ToggleButton({ id, name, label, onToggleChange, isFormSubmitted }) {
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = (event) => {
    setIsSelected(!isSelected);
    const value = isSelected ? 0 : 1;
    onToggleChange(name, value, event);
  };

  useEffect(() => {
    if (isFormSubmitted) {
      setIsSelected(false);
    }
  }, [isFormSubmitted]);

  return (
    <div
      type='button'
      id={id}
      name={name}
      onClick={handleClick}
      className={isSelected ? "selected" : "unselected"}>
      {label}
    </div>
  );
}

export default ToggleButton;
